.sncolumn {
  float: left;
  width: 200px;
  background-color: #fff;
}

/* Clear floats after the columns */
.shopnavrow:after {
  content: "";
  display: table;
  clear: both;
}